const config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  baseUrl: process.env.REACT_APP_BASE_URL,
  gtmId: process.env.REACT_APP_GTM_ID,
  gtmAuth: process.env.REACT_APP_GTM_AUTH,
  gtmPreview: process.env.REACT_APP_GTM_PREVIEW,
  sentry: {
    allowedUrls: process.env.REACT_APP_SENTRY_ALLOWED_URLS?.split(","),
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: process.env.REACT_APP_SENTRY_ENABLED,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    errorSampleRate: process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE,
    replaysOnErrorSampleRate:
      process.env.REACT_APP_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
    replaySampleRate: process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE,
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  },
};

export default config;
