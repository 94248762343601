export const getUsernameFromURL = () => {
  const hostname = window.location.hostname;
  let username;

  switch (hostname) {
    case "stuartlogan.com":
      username = "stuart";
      break;
    case "daptrax.com":
      username = "compserdap";
      break;
    case "ikaradesign.eu":
      username = "oanacarianopol5582";
      break;
    case "313mediagroup.com":
      username = "khuber8076";
      break;
    case "ericoh.ca":
      username = "echo416";
      break;
    case "galacticeclipse.com":
      username = "Phenomenone";
      break;
    case "thechaco.com":
      username = "Alejandro1366";
      break;
    case "www.kerryleatham.com":
      username = "KLeatham";
      break;
    case "cloudfoundrymusic.com":
      username = "james4921";
      break;
    case "mimosamusic.com.au":
      username = "jamesbdarcy";
      break;
    case "zibaconcept.com":
      username = "kourossissan";
      break;
    case "rsdm.com.au":
      username = "RSDM";
      break;
    case "ivelissedelcarmen.com":
        username = "ive00uk";
        break;
    case "yadirastampfli.com":
      username = "YadiraS";
      break;
    case "rossthomascreations.com":
      username = "rossthomas1";
      break;
    case "www.oztar.com":
      username = "oztarc7878";
      break;
    case "gemafawell.dev":
      username = "GemAfaWell1";
      break;
    case "hugo-sevilla-gomez-llanos.dev":
      username = "HugoSevillaGomezLl";
      break;
    case "yuripardi.com":
      username = "yuripardi4585";
      break;
    case "dandelboy.com":
      username = "DanDelboy";
      break;
    case "irekdreger.com":
      username = "Irek";
      break;
    case "nicerecords.au":
      username = "nicerecords";
      break;
    case "www.andreapossee.com":
      username = "apossee4242";
      break;
    case "lawrencetimonimusic.com":
      username = "Lawrence81";
      break;
    case "bitlabs.com.pe":
      username = "GabrielPardo";
      break;
    case "catrosinskyscreenproduction.com.au":
      username = "CatRosinsky";
      break;
    case "guillermoronda.com":
      username = "guillermorondaaran33";
      break;
    case "www.allegra-sun.com":
      username = "Allegrasun";
      break;
    case "carlmerenick.ca":
      username = "cmerenick";
      break;
    case "tobynutter.co.uk":
      username = "TobyNutter";
      break;
    default:
      username = hostname.split(".")[0];
  }
  return username;
};

export function beginsWithVowel(string) {
  return /^[aeiouAEIOU]/.test(string);
}
