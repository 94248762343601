// src/components/SocialLinks.js
import React from "react";
import InstagramIcon from "../icons/InstagramIcon";
import TwitterIcon from "../icons/TwitterIcon";
import FacebookIcon from "../icons/FacebookIcon";
import EmailIcon from "../icons/EmailIcon";

const SocialLinks = ({ links }) => {
  const renderIcon = (anchorText) => {
    switch (anchorText) {
      case "instagram":
        return <InstagramIcon />;
      case "x":
        return <TwitterIcon />;
      case "facebook":
        return <FacebookIcon />;
      case "email":
        return <EmailIcon />;
      default:
        return null;
    }
  };

  return (
    <div className="social-links">
      {links.map((link, index) => (
        <a
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderIcon(link.type)}
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
