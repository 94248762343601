// src/App.js
import { useState, useEffect } from "react";
import Profile from "./components/Profile";
import "./styles/main.css";
import { getUserData } from "./services/api";
import {
  beginsWithVowel,
  getUsernameFromURL,
} from "./services/helperFunctions";
import { Helmet } from "react-helmet";
import config from "./config";

const App = () => {
  const [template, setTemplate] = useState("default");
  const [profileData, setProfileData] = useState(null);

  const username = getUsernameFromURL();

  const headName = profileData?.displayname || username;
  const role = profileData?.featured_roles?.[0] || "";
  const location = profileData?.location?.text || "";

  const title = `${headName} - ${role || "Twine"}`;
  let metaDescription = `One-page site for ${headName}. `;

  if (role && location) {
    metaDescription += `${headName} is ${
      beginsWithVowel(role) ? "an" : "a"
    } ${role} from ${location}.`;
  } else if (role) {
    metaDescription += `${headName} is ${
      beginsWithVowel(role) ? "an" : "a"
    } ${role} on twine.net.`;
  } else if (location) {
    metaDescription += `${headName} is a freelancer from ${location} on twine.net.`;
  } else {
    metaDescription += `${headName} is a freelancer on twine.net.`;
  }

  useEffect(() => {
    getUserData(username).then((data) => {
      if (!data.active) {
        window.location.href = "https://www.twine.net";
        return;
      }

      if (!data.pro) {
        window.location.href = data.links.main;
        return;
      }

      setProfileData(data);
      const urlParams = new URLSearchParams(window.location.search);
      const urlTemplate = urlParams.get("template");
      setTemplate(urlTemplate || data?.website?.theme || null);
    });
  }, [username]);

  useEffect(() => {
    if (template) {
      import(`./styles/templates/${template}.css`).catch(() => {
        if (profileData) {
          import('./styles/templates/default.css');
        }
      });
    }
  }, [template, profileData]);

  return (
    <div className={`app`}>
      <Helmet
        title={title}
        link={[
          { rel: "preconnect", href: "https://fonts.googleapis.com" },

          {
            rel: "preconnect",
            href: "https://fonts.gstatic.com",
          },

          {
            href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
            rel: "stylesheet",
          },
          {
            rel: "canonical",
            href: `${config.baseUrl.replace("*", username)}`,
          },
        ]}
        meta={[
          { property: "og:title", content: title },
          { name: "twitter:title", content: title },
          { name: "description", content: metaDescription },
          { property: "og:description", content: metaDescription },
          { name: "twitter:description", content: metaDescription },
          {
            property: "og:url",
            content: `${config.baseUrl.replace("*", username)}`,
          },
        ]}
      />
      {!profileData ? (
        <div></div>
      ) : (
        <div className="fade-in">
          <Profile profileData={profileData} />
        </div>
      )}
    </div>
  );
};

export default App;
