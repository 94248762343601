// src/components/Profile.js
import { marked } from "marked";
import SocialLinks from "./SocialLinks";

const Profile = ({ profileData }) => {
  const avatarUrl = profileData?.avatars?.images[190]?.square?.main;

  const partition = (array, isValid) => {
    return array.reduce(
      ([pass, fail], elem) => {
        return isValid(elem)
          ? [[...pass, elem], fail]
          : [pass, [...fail, elem]];
      },
      [[], []]
    );
  };

  const userLinks = profileData?.website?.links || [];

  const [links, socialLinks] = partition(
    userLinks,
    (link) => link.type === "link"
  );

  const bioHtml = marked(profileData?.website?.website_bio ?? profileData?.bio ?? "");

  return (
    <div className="profile">
      <div className="profile-content">
        <div className="profile-column">
          <div className="profile-info">
            <div className="profile-image">
              {avatarUrl && <img src={avatarUrl} alt="Avatar" />}
            </div>
            <div className="profile-details">
              {profileData?.featured_roles && (
                <div className="featured-roles">
                  {profileData.featured_roles.slice(0, 2).map((role, index) => (
                    <div key={index} className="role">
                      {role}
                    </div>
                  ))}
                </div>
              )}
              <h1>{profileData?.displayname}</h1>
              <div className="bio-container">
                <div dangerouslySetInnerHTML={{ __html: bioHtml }}></div>
              </div>
            </div>
          </div>
          <div className="button-links">
            {links.length > 0 &&
              links.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  className="button-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.link_text}
                </a>
              ))}
          </div>
          {socialLinks.length > 0 && <SocialLinks links={socialLinks} />}
        </div>
      </div>
    </div>
  );
};

export default Profile;
